// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import compact from 'lodash/compact';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import useNodePath from '~plugins/prismic/hooks/useNodePath';
import type { PrismicRedirect } from '~schema';

import messages from './messages';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicRedirect>>,
};

const styles = (unusedTheme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const RedirectPage = ({ classes, node }: Props) => {
  const renderedRedirectsFromRows = React.useMemo(
    () =>
      node && node.data && node.data.redirect_from
        ? compact(
            node.data.redirect_from.map((redirect_from, index) =>
              redirect_from &&
              redirect_from.redirect_from_path &&
              redirect_from.redirect_from_path.text ? (
                <TableRowRedirect
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  path={redirect_from.redirect_from_path.text}
                  code={redirect_from.redirect_from_http_status_code}
                />
              ) : null,
            ),
          )
        : null,
    [node?.data?.redirect_from],
  );

  return (
    <Container component={ResPadding} vertical className={classes.root}>
      <Typography variant="h4" gutterBottom>
        {node?.data?.name?.text || (
          <FormattedMessage {...messages.page.title} />
        )}
      </Typography>
      <Typography paragraph>
        <FormattedMessage {...messages.page.redirectsTo} />
        &nbsp;
        {node?.data?.redirect_to?.url ? (
          <MuiLink
            href={node?.data?.redirect_to?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node?.data?.redirect_to?.url}
          </MuiLink>
        ) : (
          <FormattedMessage {...messages.page.valueNotSet} />
        )}
      </Typography>
      <Typography paragraph>
        <FormattedMessage {...messages.page.redirectsFrom} />
        {renderedRedirectsFromRows &&
        renderedRedirectsFromRows.length > 0 ? null : (
          <>
            &nbps;
            <FormattedMessage {...messages.page.valueNotSet} />
          </>
        )}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">
              <FormattedMessage {...messages.table.head.path} />
            </TableCell>
            <TableCell variant="head">
              <FormattedMessage {...messages.table.head.httpStatusCode} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRowRedirect
            path={useNodePath({ node }) ?? '/#'}
            code={undefined}
          />
          {renderedRedirectsFromRows}
        </TableBody>
      </Table>
    </Container>
  );
};

export default compose(
  withNodePage<PrismicRedirect, *>({
    getNode: data => data?.prismicRedirect,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(RedirectPage);

export const query = graphql`
  query RedirectPageQuery($prismicId: ID) {
    prismicRedirect(prismicId: { eq: $prismicId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        name {
          text
        }
        redirect_to {
          url
        }
        redirect_from {
          redirect_from_path {
            text
          }
          redirect_from_http_status_code
        }
      }
    }
  }
`;

function TableRowRedirect({ path, code }: { path: string, code: ?string }) {
  return (
    <TableRow>
      <TableCell>
        <pre>{path}</pre>
      </TableCell>
      <TableCell>
        <pre>{code || '301: Permanent redirect'}</pre>
      </TableCell>
    </TableRow>
  );
}

// @flow
import * as React from 'react';
import type { HOC } from 'recompose';

import type { Query } from '../../../schema.flow';
import PageHelmet from '../../helmet/components/PageHelmet';

import useNodePage, { type NodeType } from '../hooks/useNodePage';

export type WithNodePageProps<N: NodeType> = {
  isPreview: boolean,
  data: ?Query,
  node: ?N,
};

export type Props<N: NodeType, E: {}> = E &
  $Diff<WithNodePageProps<N>, { isPreview: any }>;

export type WithNodePageHOC<N: NodeType, E: {}> = HOC<
  { ...$Exact<Props<N, E>> },
  Props<N, E>,
>;

export type Config<N: NodeType> = {|
  getNode: (data: ?Query) => ?N,
|};

export default function withNodePage<Node: NodeType, Enhanced: {}>({
  getNode,
}: Config<Node>): WithNodePageHOC<Node, Enhanced> {
  return Component => {
    const WithNodePage = ({
      data: staticData,
      ...props
    }: Props<Node, Enhanced>) => {
      const { isPreview, data, node } = useNodePage<Node>({
        staticData,
        getNode,
      });
      return (
        <>
          <Component
            {...props}
            isPreview={isPreview}
            node={node}
            data={data || staticData}
          />
          <PageHelmet node={node} />
        </>
      );
    };

    return WithNodePage;
  };
}
